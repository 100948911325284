<template>
  <div>
    <internet-movil-table-head
      @purchase="openPurchase"
    ></internet-movil-table-head>

    <div class="d-flex align-center mt-5">
      <v-text-field
        v-model="search"
        class="mr-5"
        hide-details
        placeholder="Buscar plan de internet móvil"
        prepend-inner-icon="mdi-magnify"
        background-color="#DDDDE4"
        color="primary"
        outlined
      ></v-text-field>
      <v-btn-toggle
        v-model="filter"
        color="white"
        outlined
        group
        dark
        class="primary"
      >
        <v-btn value="TODAS"> TODAS </v-btn>
        <v-btn value="PAGADA"> PAGADAS </v-btn>
        <v-btn value="PAGO PENDIENTE"> PENDIENTES </v-btn>
      </v-btn-toggle>
    </div>
    <internet-movil-table-item
      v-for="item in itemsFilters"
      :key="item._id"
      :item="item"
    ></internet-movil-table-item>

    <v-progress-linear v-if="isLoading" indeterminate />

    <p v-if="itemsFilters.length === 0" class="text-center mt-2">
      No hay resultados
    </p>

    <component
      :is="component"
      :dialog="dialog"
      :purchase="purchase"
      @pay="pay"
      @close="close"
      @cancel="cancel"
      @saleGenerated="saleGenerated"
    ></component>
  </div>
</template>

<script>
// Domain
import Purchase from "../../domain/Purchase";

//EventBus
import Bus from "../../common/EventBus";

export default {
  name: "InternetMovilTable",
  async mounted() {
    Bus.$on("refreshView", async () => {
      this.items = await Purchase.getAllMovil();
    });
    this.items = await Purchase.getAllMovil();
    this.isLoading = false;
    this.showItems = true;
  },
  components: {
    InternetMovilTableHead: () => import("./table/InternetMovilTableHead"),
    InternetMovilTableItem: () => import("./table/InternetMovilTableItem"),
    InternetMovilPurchase: () => import("./InternetMovilPurchase"),
    InternetMovilConfirm: () => import("./InternetMovilConfirm")
  },
  data: () => ({
    isLoading: true,
    component: "InternetMovilConfirm",
    filter: "TODAS",
    dialog: false,
    purchase: {
      idClient: "",
      description: "Sin descripción para este plan",
      days: "",
      // hours: 0,
      price: "",
      status: "PAGADA",
      type: "INTERNET MOVIL"
    },
    items: [],
    search: ""
  }),
  methods: {
    openPurchase() {
      this.dialog = true;
      this.component = "InternetMovilPurchase";
    },
    pay(purchase) {
      this.purchase = purchase;
      this.component = "InternetMovilConfirm";
    },
    cancel(purchase) {
      this.purchase = purchase;
      this.component = "InternetMovilPurchase";
    },
    async saleGenerated() {
      try {
        this.items = await Purchase.getAllMovil();
        this.purchase = this.purchaseClean();
        this.dialog = false;
      } catch (error) {
        console.warn(error);
      }
    },
    close() {
      this.dialog = false;
      this.purchase = this.purchaseClean();
    },
    purchaseClean() {
      return {
        idClient: "",
        description: "Sin descripción para este plan",
        days: "",
        // hours: 0,
        price: "",
        status: "PAGADA",
        type: "INTERNET MOVIL"
      };
    }
  },
  computed: {
    itemsFilters() {
      return this.items
        .filter(
          item =>
            String(item.days)
              .toUpperCase()
              .includes(this.search.toUpperCase()) ||
            String(item.price)
              .toUpperCase()
              .includes(this.search.toUpperCase()) ||
            item.client.toUpperCase().includes(this.search.toUpperCase())
        )
        .filter(item => {
          return this.filter === "TODAS" || item.status === this.filter;
        });
    }
  }
};
</script>

<style lang="scss">
.info--date {
  width: 25%;
}

.info--name {
  width: 25%;
  padding-left: 1rem;
}

.info--validity {
  width: 10%;
}

.info--price {
  width: 10%;
}

.info--status {
  width: 15%;
}

.info--actions {
  width: 15%;
}
</style>